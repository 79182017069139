<!-- 布局容器 -->
<template>
  <div class="layout">
    <van-notice-bar
        left-icon="volume-o"
        wrapable
        :scrollable="false"
        text="1月20日，由于官方更新，导致大部分账号掉线，老版本已不可用，请更新到新版本"
    />
    <van-notice-bar
        left-icon="volume-o"
        wrapable
        :scrollable="false"
        text="1月19日，更新了英雄招募，自动集结，宠物寻宝，自动治疗，日常任务，优化了运行速度。"
    />

    <van-notice-bar
        left-icon="volume-o"
        wrapable
        :scrollable="false"
        text="⬇️⬇️点击按钮升级系统⬇️⬇️"
    />

    <van-row gutter="48" type="flex" justify="center" style="margin: 8px">
      <van-col span="24">
        <van-button style="width: 100%;" size="normal" round type="danger"
                    @click="move">
          ⚡️点我升级系统⚡️
        </van-button>
      </van-col>
    </van-row>


    <van-dialog v-model="showMoveDialog" title="版本升级成功" :show-confirm-button="false" style="width: 90%; height: 60%">
      <van-notice-bar
          wrapable
          :scrollable="false"
          text="⬇️复制下方链接到浏览器打开⬇️"
      />

      <van-field style="margin-top: 32px" v-model="newUrl" label="新链接"/>


      <van-row gutter="48" type="flex" justify="center" style="margin-top: 32px">
        <van-col span="24">
          <van-button style="width: 100%" size="normal" round type="danger"
                      @click="on_copy_url2">
            🔗点我直接复制🔗
          </van-button>
        </van-col>

        <van-col span="24">
          <van-button style="width: 100%; margin-top: 24px" size="normal" round type="info"
                      @click="on_copy_url3">
            🔗点我直接跳转🔗
          </van-button>
        </van-col>
      </van-row>
      <van-notice-bar
          left-icon="volume-o"
          wrapable
          :scrollable="false"
          text="1月20日，由于官方更新，导致大部分账号掉线，老版本已不可用，请更新到新版本"
      />
      <van-notice-bar
          left-icon="volume-o"
          wrapable
          :scrollable="false"
          text="1月19日，更新了英雄招募，自动集结，宠物寻宝，自动治疗，日常任务，优化了运行速度。"
      />
    </van-dialog>


    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list v-model="loading"
                :finished="finished"
                finished-text="下拉刷新"
                @load="onLoad">
        <van-cell-group class="cell-group" title="账号信息" inset>
          <van-cell
              :title="''+this.user_info.name + ' ['+this.user_info.vip_card +']' + (this.user_info.err_sdktoken == 0 ? ' 😊' : ' 😭')"
              :label="'账号:'+ this.user_info.fid + '｜到期:'+ this.user_info.expire_time">
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>


</template>

<script>
import Vue from 'vue';
import {NavBar} from 'vant';
import {Cell, CellGroup, PullRefresh} from 'vant';
import {Progress} from 'vant';
import {Tag} from 'vant';
import {Col, Row} from 'vant';
import {Toast} from 'vant';
import {Popup} from 'vant';
import {Switch} from 'vant';
import {Stepper} from 'vant';
import {Dialog} from 'vant';

import {Search} from 'vant';

Vue.use(Search);

import {NoticeBar} from 'vant';

Vue.use(NoticeBar);

Vue.use(NavBar);
Vue.use(PullRefresh);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Toast);
Vue.use(Progress);
Vue.use(Col);
Vue.use(Row);
Vue.use(Popup);
Vue.use(Stepper);
Vue.use(Switch);
Vue.use(Dialog);

import {get_userinfo, do_move} from '@/api/index';
import App from "@/App.vue";

export default {
  name: 'layout',
  components: {App},
  props: {},
  data() {
    return {
      newUrl: '',
      showMoveDialog: false,
      user_info: {},
      setting: [],
      queue: [],
      loading: false,
      finished: false,
      isLoading: false,
      show: false,
      showDialog: false,
      uid: '',
      inviteId: '',
      inviteList: '[]'
    }
  },
  computed: {},
  watch: {},
  created() {
    Toast.setDefaultOptions({duration: 333});
  },
  mounted() {
    this.uid = /[a-z0-9]+/i.exec(this.$route.params["id"]).pop()
  },
  methods: {
    move() {
      do_move({uid: this.uid}).then(data => {
        console.log(data)
        if (data.type === 1) {
          Toast({message: data.info, duration: 1000});
        }
        if (data.type === 2) {
          this.showMoveDialog = true
          this.newUrl = data.path

        }
      })
    },
    on_copy_url2() {
      this.$copyText('' + this.newUrl).then(
          function (e) {
            Toast({message: '复制成功', duration: 2000});
          },
          function (e) {
            Toast.fail('复制失败');
          }
      );
    },
    on_copy_url3() {
      window.location.href = '' + this.newUrl;
    },
    onLoad() {
      get_userinfo({uid: this.uid}).then(data => {
        for (let k in data) {
          Reflect.set(this.user_info, k, data[k])
        }
        if (data['newUrl'] !== "") {
          this.newUrl = data['newUrl']
          this.showMoveDialog = true
        }
      }).catch(e => {
      })
    },
    onRefresh() {
      this.isLoading = true
      this.onLoad();
    },
  },
}
</script>

<style scoped lang="less">
.layout {
  margin: 8px;
  background-color: #f0f0f0;
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

</style>
